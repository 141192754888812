<template>
  <div class="countdown-container">
    <img src="@/assets/incRP2.png" alt="Logo" class="logo" />
    <h1 class="headline">Już wkrótce strona będzie dostępna</h1>
    <div class="countdown-timer">
      <div class="time-unit">
        <span class="number">{{ days }}</span>
        <span class="label">Dni</span>
      </div>
      <div class="time-unit">
        <span class="number">{{ hours }}</span>
        <span class="label">Godzin</span>
      </div>
      <div class="time-unit">
        <span class="number">{{ minutes }}</span>
        <span class="label">Minut</span>
      </div>
      <div class="time-unit">
        <span class="number">{{ seconds }}</span>
        <span class="label">Sekund</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  data() {
    return {
      timeRemaining: 0,
      intervalId: null,
    };
  },
  computed: {
    days() {
      return Math.floor(this.timeRemaining / (1000 * 60 * 60 * 24));
    },
    hours() {
      return Math.floor((this.timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    },
    minutes() {
      return Math.floor((this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    },
    seconds() {
      return Math.floor((this.timeRemaining % (1000 * 60)) / 1000);
    },
  },
  methods: {
    calculateTimeRemaining() {
      const now = new Date();
      const nextSunday = this.getNextSunday();
      this.timeRemaining = nextSunday - now;
    },
    getNextSunday() {
      const now = new Date();
      const dayOfWeek = now.getDay();
      const daysUntilSunday = (7 - dayOfWeek) % 7;
      const nextSunday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + daysUntilSunday,
        0, 0, 0, 0
      );
      return nextSunday;
    },
  },
  created() {
    this.calculateTimeRemaining();
    this.intervalId = setInterval(() => {
      this.calculateTimeRemaining();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
/* Stylizacja tła z animowanym gradientem */
.countdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 98vh;
  background: linear-gradient(135deg, #6a11cb, #a201ff, #a201ff, #e52e71);
  background-size: 300% 300%;
  animation: gradientAnimation 10s ease infinite;
  color: white;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

/* Stylizacja logo */
.logo {
  width: 150px;
  margin-bottom: 20px;
  animation: rotateIn 2s ease-in-out;
}

/* Stylizacja nagłówka */
.headline {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: fadeIn 2s ease-in-out;
}

/* Stylizacja timera */
.countdown-timer {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  animation: pulse 1s infinite;
}

.label {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Animacje CSS */
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes rotateIn {
  0% { transform: rotate(-360deg); opacity: 0; }
  100% { transform: rotate(0deg); opacity: 1; }
}
</style>