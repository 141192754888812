import { createRouter, createWebHistory } from 'vue-router';
import Sidebar from '@/components/Sidebar.vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Profile from '@/views/Profile.vue';
import AcceptRules from '@/views/AcceptRules.vue';
import SettingAdmin from '@/views/admin/SettingsPage.vue';
import SerwerStatus from '@/views/fivem/InfoServer.vue';
import Podania from '@/views/fivem/Applications.vue';
import CharakterList from '@/views/fivem/CharakterList.vue';
import CharacterDetails from '@/views/fivem/CharacterDetails.vue';
import MaintenancePage from '@/views/MaintenancePage.vue';
import UserList from '@/views/admin/UserList.vue';
import UserDetails from '@/views/admin/UserDetailsModal.vue';
import SettingPodania from '@/views/fivem/SettingsApplications.vue';
import ApplicationsList from '@/views/fivem/bossmenu/ApplicationsList.vue';
import MapGta from '@/views/fivem/MapGta.vue'
import TechSupportPanel from '@/views/admin/TechSupportPanel.vue';

import TabletLogin from '@/views/fivem/tablet/TabletLogin.vue';
import Desktop from '@/views/fivem/tablet/Desktop.vue';

const routes = [
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: MaintenancePage,
    meta: {
      title: 'Przerwa Techniczna'
    },
  },
  {
    path: '/',
    component: Sidebar,
    meta: {
      title: 'Strona Główna'
    },
    children: [
      {
        path: '',
        name: 'WebHome',
        component: Home,
        meta: {
          title: 'Strona Główna'
        }
      },
      {
        path: 'profile',
        name: 'WebProfile',
        component: Profile,
        meta: {
          title: 'Profil',
          requiresAuth: true
        }
      },
      {
        path: '/settingsadmin',
        name: 'WebAdminSetting',
        component: SettingAdmin,
        meta: {
          title: 'Ustawienia Admina'
        }
      },
      {
        path: '/infofivem',
        name: 'WebInfoFivem',
        component: SerwerStatus,
        meta: {
          title: 'Status Serwera'
        }
      },
      {
        path: '/applications',
        name: 'WebApplication',
        component: Podania,
        meta: {
          title: 'Podania'
        }
      },
      {
        path: '/lista-postaci',
        name: 'WebCharakterList',
        component: CharakterList,
        meta: {
          title: 'Lista Postaci'
        }
      },
      {
        path: '/character/:citizenid',
        name: 'CharacterDetails',
        component: CharacterDetails,
        meta: {
          title: 'Lista Postaci'
        }
      },
      {
        path: '/userslist',
        name: 'UserList',
        component: UserList,
        meta: {
          title: 'Lista użytkowników'
        }
      },
      {
        path: '/users/:id',
        name: 'user-details',
        component: UserDetails,
        meta: {
          title: 'Informacje o użytkowniku'
        },
        props: true
      },
      {
        path: '/podaniasetting',
        name: 'WebSettingsApplications',
        component: SettingPodania,
        meta: {
          title: 'Ustawienia Podań'
        },
      },
      {
        path: '/lista-podan',
        name: 'WebApplicationsList',
        component: ApplicationsList,
        meta: {
          title: 'Lista Podań'
        },
      },
      {
        path: '/mapa',
        name: 'WebGtaMap',
        component: MapGta,
        meta: {
          title: 'Mapa'
        },
      },
      {
        path: '/ticketpanel',
        name: 'TechSupportPanel',
        component: TechSupportPanel,
        meta: {
          title: 'Lista Ticketów'
        },
      },
    ]
  },
  {
    path: '/accept-rules',
    name: 'AcceptRules',
    component: AcceptRules,
    meta: {
      title: 'Regulamin'
    }
  },
  {
    path: '/login',
    name: 'WebLogin',
    component: Login,
    meta: {
      title: 'Zaloguj Się',
    }
  },
  {
    path: '/tablet',
    name: 'TabletLogin',
    component: TabletLogin,
    meta: {
      title: 'Logowanie do komputera',
    }
  },
  {
    path: '/desktop/:profile',
    name: 'desktop',
    component: Desktop,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      // Sprawdzenie, czy użytkownik jest zalogowany
      const token = localStorage.getItem('token');
      if (!token) {
        next('/'); // Jeśli brak tokenu, przekierowanie do logowania
      } else {
        next(); // Jeśli token jest, przejdź do pulpitu
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  document.title = `INC - ${to.meta.title || 'Default Title'}`;

  // Sprawdzenie i zapis tokena
  const token = new URLSearchParams(window.location.search).get('token');
  if (token) {
    localStorage.setItem('token', token);
    window.history.replaceState({}, document.title, window.location.pathname);
  }

  // Ochrona tras wymagających autoryzacji
  const loggedIn = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;