<template>
  <div class="desktop-container">
    <!-- Pulpit -->
    <div class="desktop">
      <div v-for="(app, index) in apps" :key="index" class="app-icon" @click="openApp(app)">
        <img :src="app.icon" :alt="app.name" />
        <p>{{ app.name }}</p>
      </div>
    </div>

    <!-- Pasek zadań -->
    <div class="taskbar">
      <!-- Ikona Start -->
      <button class="start-button" @click="toggleMenu">
        <!-- <img src="@/assets/icons/start-icon.png" alt="Start" /> -->
      </button>

      <!-- Aplikacje w pasku -->
      <div class="taskbar-apps">
        <div v-for="(app, index) in openApps" :key="index" class="taskbar-icon">
          <img :src="app.icon" :alt="app.name" />
        </div>
      </div>

      <!-- Data i godzina -->
      <div class="time">
        {{ currentTime }}
      </div>
    </div>

    <!-- Menu start -->
    <div v-if="menuOpen" class="start-menu">
      <div class="user-info">
        <h2>{{ user.firstName }} {{ user.lastName }}</h2>
        <p>Jesteś zalogowany na: {{ user.profileName }}</p>
      </div>
      <ul>
        <li @click="logout">Wyloguj</li>
        <!-- Możliwość dodania innych opcji w menu -->
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "DesktopWeb",
  data() {
    return {
      apps: [
        // { name: 'App 1', icon: require('@/assets/icons/app1.png') },
        // { name: 'App 2', icon: require('@/assets/icons/app2.png') },
        // Dodaj więcej aplikacji
      ],
      openApps: [], // Lista otwartych aplikacji
      currentTime: new Date().toLocaleTimeString(),
      menuOpen: false,
      user: {
        firstName: '',
        lastName: '',
        profileName: '',
        // Możesz dodać więcej danych o użytkowniku, takich jak tokeny, identyfikatory
      },
    };
  },
  methods: {
    // Metoda otwierania menu
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    // Metoda logowania
    openApp(app) {
      console.log(`Otwieram aplikację: ${app.name}`);
      // Dodaj logikę otwierania aplikacji
    },
    // Metoda wylogowania
    logout() {
      console.log('Wylogowywanie...');
      localStorage.removeItem('token');
      this.$router.push('/tablet');
    },
    // Pobieranie danych użytkownika z serwera
    async fetchUserData() {
      const token = localStorage.getItem('token');

      if (!token) {
        console.log('Brak tokenu JWT. Użytkownik nie jest zalogowany.');
        this.$router.push('/login');
        return;
      }

      try {
        // Wysyłanie żądania do serwera w celu pobrania danych użytkownika
        const response = await axios.get('https://api.incrp.pl/api/tablet/user/me', {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data) {
          // Aktualizacja danych użytkownika
          this.user.firstName = response.data.firstName;
          this.user.lastName = response.data.lastName;
          this.user.profileName = response.data.profileName;
        }
      } catch (error) {
        console.error('Błąd pobierania danych użytkownika:', error);
      }
    },
  },
  created() {
    // Aktualizacja godziny
    setInterval(() => {
      this.currentTime = new Date().toLocaleTimeString();
    }, 1000);

    // Pobranie danych użytkownika przy załadowaniu komponentu
    this.fetchUserData();
  },
};
</script>

<style scoped>
.desktop-container {
  height: 98vh;
  background-color: #2c3e50;
  position: relative;
}

.desktop {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  height: calc(100vh - 60px);
}

.app-icon {
  width: 80px;
  text-align: center;
  cursor: pointer;
}

.app-icon img {
  width: 50px;
  height: 50px;
}

.taskbar {
  background-color: #333;
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.start-button img {
  width: 30px;
  height: 30px;
}

.taskbar-apps {
  display: flex;
  gap: 10px;
}

.taskbar-icon img {
  width: 30px;
  height: 30px;
}

.time {
  color: white;
}

.start-menu {
  position: absolute;
  left: 10px;
  bottom: 60px;
  background-color: #1a1a1a;
  color: white;
  width: 200px;
  padding: 10px;
  border-radius: 5px;
}

.start-menu button {
  background-color: #555;
  color: white;
  border: none;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.start-menu .user-info {
  margin-bottom: 10px;
}

.start-menu .user-info h2 {
  margin: 0;
}

.start-menu ul {
  list-style-type: none;
  padding: 0;
}

.start-menu li {
  cursor: pointer;
  padding: 10px;
}

.start-menu li:hover {
  background-color: #333;
}
</style>
