<template>
  <div class="MainProfile">
    <div class="text-profile">
      {{ $t('profile.title') }}
      <!-- Flag buttons for language change -->
      
    </div>
    <div class="profile-header">
      <img :src="user.avatar" alt="Avatar" class="avatar" />
    </div>
    <div class="profile-info">
      <div class="language-selector">
        {{ $t('profile.language') }}:
        <button @click="changeLanguage('pl')" class="flag-button">
          <img src="../assets/images/flag/pl-flag.png" alt="Polski" class="flag" />
        </button>
        <button @click="changeLanguage('en')" class="flag-button">
          <img src="../assets/images/flag/en-flag.png" alt="Angielski" class="flag" />
        </button>
      </div>
      <div v-if="user">
        <p>{{ $t('profile.name') }}: {{ user.username }}</p>
        <p>{{ $t('profile.discordid') }}: {{ user.discordId }}</p>
        <p>{{ $t('profile.client-number') }}: {{ user.clientNumber }}</p>

        <!-- Sekcja statusu regulaminu -->
        <p v-if="user.hasAcceptedRules" class="accepted">
          {{ $t('profile.regulations.text') }}: <span class="status accepted">{{ $t('profile.regulations.yes') }}</span>
        </p>
        <p v-else class="not-accepted">
          {{ $t('profile.regulations.text') }}: <span class="status not-accepted">{{ $t('profile.regulations.not') }}</span>
        </p>

        <!-- Nowa sekcja z listą rang -->
        <div class="user-roles">
          <h4>{{ $t('profile.ranks') }}:</h4>
          <ul>
            <li v-for="role in user.SiteRoles" :key="role.id">
              <!-- Wyświetlanie ikony, jeśli istnieje -->
              <img v-if="role.iconPath" :src="role.iconPath" alt="Role icon" class="role-icon" />
              {{ role.roleName }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <p>{{ $t('profile.error.not-login') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WebProfile',
  data() {
    return {
      user: {
        username: '',
        avatar: '',
        discordId: '',
        hasAcceptedRules: false,
        SiteRoles: []  // Dodajemy tablicę dla ról użytkownika
      }
    };
  },
  methods: {
    async fetchUserProfile() {
      const urlParams = new URLSearchParams(window.location.search);
      let token = urlParams.get('token');

      if (!token) {
        token = localStorage.getItem('token');
      }

      if (token) {
        try {
          localStorage.setItem('token', token);

          const response = await axios.get('https://api.incrp.pl/api/profile', {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.user = response.data;

          if (!this.user.hasAcceptedRules) {
            this.$router.push('/accept-rules');
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
          this.$router.push('/login');
        }
      } else {
        this.$router.push('/login');
      }
    },
    changeLanguage(language) {
      this.$i18n.locale = language; // Zmiana języka
    }
  },
  async created() {
    await this.fetchUserProfile();
  }
};
</script>

<style scoped>
/* Stylizacja profilu */
.MainProfile {
  width: 100%;
  height: 98vh;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 30px rgba(81, 1, 97, 0.568);
  backdrop-filter: blur(5px);
}

.profile-header {
  margin-top: 1%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid rgb(172, 0, 224);
  box-shadow: 0 10px 50px rgb(172, 0, 224);
}

.profile-info {
  padding: 20px;
  border-radius: 10px;
  color: #ffffff;
}

.text-profile {
  margin-top: 1%;
  background-color: #a100c9be;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border: 1px solid #000000;
  width: 98%;
}

/* Stylizacja przycisków języka */
.language-selector {
  display: inline-flex;
  margin-left: 10px;
}

.flag-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.flag {
  width: 30px;
  height: 20px;
  margin-left: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.flag:hover {
  transform: scale(1.1);
  transition: transform 0.2s ease-in-out;
}

/* Stylizacja sekcji ról */
.user-roles {
  margin-top: 20px;
}

.user-roles h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.user-roles ul {
  list-style-type: none;
  padding: 0;
}

.user-roles li {
  font-size: 16px;
  margin-bottom: 5px;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
}

.role-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;  /* Ustawia ikonę w linii z tekstem */
  background: #ffffff;
  border-radius: 20%;
}
</style>