<template>
  <div class="MainCharacterDetails">
    <div class="character-avatar">
      <img :src="profileImage(characterDetails)" alt="Avatar" class="avatar" v-if="characterDetails"/>
    </div>
    <h2>Szczegóły postaci</h2>
    <div v-if="characterDetails">
      <p><strong>Imię:</strong> {{ characterDetails.charinfo.firstname }}</p>
      <p><strong>Nazwisko:</strong> {{ characterDetails.charinfo.lastname }}</p>
      <p><strong>Citizen ID:</strong> {{ characterDetails.citizenid }}</p>
      <p><strong>Narodowość:</strong> {{ characterDetails.charinfo.nationality }}</p>
      <p><strong>Numer Konta:</strong> {{ characterDetails.charinfo.account }}</p>
      <p><strong>Numer Telefonu:</strong> {{ characterDetails.charinfo.phone }}</p>
      <p><strong>Płeć:</strong> {{ translateGender(characterDetails.charinfo.gender) }}</p> <!-- Dodane pole -->
      <p><strong>Stan konta:</strong> ${{ characterDetails.money.bank }}</p>
      <p><strong>Praca:</strong> {{ characterDetails.job.label }}</p>
      <p><strong>Gang:</strong> {{ characterDetails.gang.label }}</p>
    </div>
    <div v-else>
      <p>Ładowanie danych postaci...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import defaultAvatarMale from '@/assets/images/ped/profile-male.jpg'; // Zdjęcie dla mężczyzn
import defaultAvatarFemale from '@/assets/images/ped/profile-female.jpg'; // Zdjęcie dla kobiet

export default {
  name: 'CharacterDetails',
  data() {
    return {
      characterDetails: null
    };
  },
  methods: {
    async fetchCharacterDetails(citizenid) {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`https://api.incrp.pl/api/characters/${citizenid}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Parsowanie pól, które są w formacie JSON
        const character = response.data;
        this.characterDetails = {
          ...character,
          charinfo: JSON.parse(character.charinfo),
          money: JSON.parse(character.money),
          job: JSON.parse(character.job),
          gang: JSON.parse(character.gang)
        };
      } catch (error) {
        console.error('Błąd podczas pobierania szczegółów postaci:', error.response || error);
        if (error.response && error.response.status === 404) {
          alert('Postać nie została znaleziona.');
        } else {
          alert('Wystąpił błąd serwera. Spróbuj ponownie później.');
        }
      }
    },
    profileImage(character) {
      if (character && character.charinfo.gender === 'male') {
        return defaultAvatarMale;
      } else if (character && character.charinfo.gender === 'female') {
        return defaultAvatarFemale;
      } else {
        return defaultAvatarMale; // Domyślnie zdjęcie dla mężczyzn
      }
    },
    translateGender(gender) {
      if (gender === 'male') {
        return 'Mężczyzna';
      } else if (gender === 'female') {
        return 'Kobieta';
      } else {
        return 'Nieznana'; // Wartość domyślna, jeśli brak danych
      }
    }
  },
  created() {
    const citizenid = this.$route.params.citizenid; // Pobiera citizenid z parametrów trasy
    this.fetchCharacterDetails(citizenid);
  }
};
</script>

<style scoped>
.MainCharacterDetails {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  color: white;
  max-width: 100%;
  margin: 0 auto;
  backdrop-filter: blur(5px);
}

.character-avatar {
  display: flex;
  margin-bottom: 20px;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ac00e0;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
}
</style>
