<template>
  <div class="application-review">
    <h1>Lista Podań</h1>

    <!-- Wyszukiwarka po numerze podania -->
    <div class="search-container">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Wyszukaj po numerze podania"
        @input="searchApplications"
      />
    </div>

    <!-- Lista podań -->
    <div v-if="!selectedApplication && filteredApplications.length === 0">
      <p>Brak dostępnych podań.</p>
    </div>

    <div v-if="!selectedApplication">
      <ul class="application-list">
        <li v-for="application in filteredApplications" :key="application.id" @click="viewApplication(application)">
          <span>{{ application.applicationType }} - Podanie #{{ application.id }}</span>
          <span>Status: {{ application.status }}</span>
        </li>
      </ul>
    </div>

    <!-- Szczegóły podania -->
    <div v-else-if="selectedApplication">
        <h2>Szczegóły Podania #{{ selectedApplication.id }}</h2>
        <p><strong>Typ Podania:</strong> {{ selectedApplication.applicationType }}</p>

        <p v-if="selectedApplication.user && selectedApplication.user.username">
            <strong>Autor Podania:</strong> {{ selectedApplication.user.username }}
        </p>
        <p v-else>
            <strong>Autor Podania:</strong> Nieznany
        </p>

        <!-- Dodanie wyświetlania treści opisu -->
        <p v-if="selectedApplication.description">
            <strong>Treść:</strong> {{ selectedApplication.description }}
        </p>
        <p v-else>
            <strong>Treść:</strong> Brak opisu.
        </p>

        <p><strong>Status:</strong> {{ selectedApplication.status }}</p>

        <button @click="acceptApplication(selectedApplication.id)">Akceptuj</button>
        <button @click="rejectApplication(selectedApplication.id)">Odrzuć</button>
        <button @click="backToList">Wróć do listy</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      applications: [], // Wszystkie podania
      filteredApplications: [], // Filtrowane podania do wyświetlania na liście
      searchQuery: '', // Pole wyszukiwania
      selectedApplication: null, // Wybrane podanie do wyświetlenia szczegółów
    };
  },
  async created() {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.incrp.pl/api/applications', {
        headers: {
            Authorization: `Bearer ${token}`
        }
        });
        console.log('Pobrane dane:', response.data); // Sprawdź odpowiedź z API
        this.applications = response.data; // Zapisuje pobrane podania
        this.filteredApplications = this.applications; // Domyślnie wszystkie podania
    } catch (error) {
        console.error('Błąd podczas pobierania podań:', error);
        alert('Nie udało się pobrać podań');
    }
  },
  methods: {
    searchApplications() {
        const query = this.searchQuery.toLowerCase();
        this.filteredApplications = this.applications.filter(application => 
        application.id.toString().includes(query)
        );
    },
    viewApplication(application) {
        this.selectedApplication = application;
    },
    async acceptApplication(id) {
        try {
        const token = localStorage.getItem('token');
        await axios.put(
            'https://api.incrp.pl/api/applications/update-status', 
            {
            applicationId: id,
            status: 'accepted' // Ustawienie statusu jako 'accepted'
            },
            {
            headers: {
                Authorization: `Bearer ${token}`
            }
            }
        );
        // Aktualizuj lokalnie stan aplikacji
        this.selectedApplication.status = 'accepted';
        alert(`Podanie ${id} zostało zaakceptowane`);
        } catch (error) {
        console.error('Błąd podczas akceptowania podania:', error);
        alert('Wystąpił błąd podczas akceptowania podania.');
        }
    },
    async rejectApplication(id) {
        try {
        const token = localStorage.getItem('token');
        await axios.put(
            'https://api.incrp.pl/api/applications/update-status', 
            {
            applicationId: id,
            status: 'rejected' // Ustawienie statusu jako 'rejected'
            },
            {
            headers: {
                Authorization: `Bearer ${token}`
            }
            }
        );
        // Aktualizuj lokalnie stan aplikacji
        this.selectedApplication.status = 'rejected';
        alert(`Podanie ${id} zostało odrzucone`);
        } catch (error) {
        console.error('Błąd podczas odrzucania podania:', error);
        alert('Wystąpił błąd podczas odrzucania podania.');
        }
    },
    backToList() {
        this.selectedApplication = null;
    }
    }
};
</script>

<style scoped>
.application-review {
  padding: 20px;
}

.search-container {
  margin-bottom: 20px;
}

.search-container input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 400px;
}

.application-list {
  list-style-type: none;
  padding: 0;
}

.application-list li {
  padding: 15px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.application-list li:hover {
  background-color: #f5f5f5;
}

button {
  padding: 10px 15px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}
</style>