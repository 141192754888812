<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    class="icon"
  >
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
      <path d="M2056 5092 c-40 -77 -501 -1431 -490 -1441 5 -6 1464 1450 1464 1461
      0 5 -216 8 -480 8 l-479 0 -15 -28z"/>
      <path d="M2297 4257 c-755 -750 -788 -785 -817 -850 -37 -82 -212 -598 -205
      -605 7 -8 1925 1902 1925 1918 0 22 -60 228 -81 277 -10 23 -22 43 -26 43 -4
      -1 -362 -353 -796 -783z"/>
      <path d="M2950 4086 c-190 -190 -346 -350 -348 -356 -5 -14 18 -120 26 -120 7
      0 232 222 534 527 178 179 180 182 174 217 -4 19 -14 44 -24 56 -17 20 -21 16
      -362 -324z"/>
      <path d="M3080 3665 c-353 -352 -361 -361 -355 -393 10 -56 193 -709 446
      -1597 133 -467 264 -931 291 -1030 105 -384 156 -562 174 -600 l19 -40 560 -3
      c308 -1 565 0 572 2 9 4 -80 281 -288 898 -166 491 -467 1388 -670 1993 -202
      605 -373 1107 -378 1115 -7 12 -85 -60 -371 -345z"/>
      <path d="M1777 2912 c-373 -372 -691 -697 -706 -722 -32 -53 -68 -141 -78
      -190 -5 -31 38 8 403 367 695 682 979 972 1007 1028 29 59 60 158 55 180 -2 9
      -276 -258 -681 -663z"/>
      <path d="M1511 2093 l-722 -718 -56 -155 c-122 -340 -412 -1203 -407 -1211 3
      -5 254 -9 558 -9 l554 0 11 48 c7 26 115 403 241 837 287 991 403 1396 473
      1650 70 252 76 275 72 275 -1 0 -327 -323 -724 -717z"/>
    </g>
  </svg>
</template>

<style scoped>
.icon {
  width: 24px;  /* Możesz dostosować rozmiar ikony */
  height: 24px; /* Możesz dostosować rozmiar ikony */
}
</style>