<template>
  <div class="container">
      <div class="accept-rules-container">
          <h1>Akceptacja regulaminu</h1>
          <p>Przed kontynuowaniem proszę zaakceptować regulamin.</p>
          
          <!-- Tekst regulaminu -->
          <div class="rules-text">
              <h3>Postanowienia Ogólne</h3>
              <p>1.1 - Nieznajomość regulaminu nie zwalnia z jego przestrzegania.</p>
              <p>1.2 - Administracja ma pełne prawa do zmieniania treści regulaminu.</p>
              
              <h3>Zasady kanałów tekstowych oraz głosowych</h3>
              <p>2.1 - Nie spamuj (wiadomościami, emotkami, linkami, zdjęciami etc.) oraz powstrzymaj się od floodowania.</p>
              <p>2.2 - Pisz normalnie - nie nadużywaj dużych liter (CapsLock)</p>
              <p>2.3 - Staraj się trzymać tematu – wypowiadaj się w dedykowanych kanałach/tematach.</p>
              <p>2.4 - Nie nadużywaj przekleństw na kanałach głosowych oraz tekstowych.</p>
              <p>2.5 - Wypowiadaj się z szacunkiem do innych użytkowników.</p>
              <p>2.6 - Nie reklamuj niczego bez zgody @Owner</p>
              <p>2.7 - Zakazane jest poruszanie tematów wulgarnych/erotycznych/religijnych/rasistowskich itp.</p>
              <p>2.8 - Zakaz wykorzystywania możliwych błędów na serwerze. Wszelkie błędy należy bezzwłocznie zgłosić do @ADMINISTRATION TEAM.</p>
              <p>2.9 - Zakazane jest używanie słów o podtekstach rasistowskich, ksenofobicznych, faszystowskich etc.</p>

              <p>Więcej informacji można znaleźć tutaj: 
                <a href="https://safety.twitch.tv/s/article/Community-Guidelines?language=pl" target="_blank" class="btn-twitch">Regulamin Twitch</a>
              </p>
          </div>

          <!-- Przycisk akceptacji regulaminu -->
          <div v-if="loading" class="loader">Proszę czekać...</div>
          <button v-else @click="acceptRules" class="btn-accept">Akceptuję</button>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
name: 'AcceptRules',
data() {
  return {
    loading: false
  };
},
methods: {
  async acceptRules() {
    const token = localStorage.getItem('token');
    this.loading = true;
    try {
      const response = await axios.post('https://api.incrp.pl/api/accept-rules', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Rules accepted:', response.data);
      this.loading = false;

      // Przekierowanie na profil po udanej akceptacji
      this.$router.push('/profile');
    } catch (error) {
      console.error('Błąd przy akceptacji regulaminu:', error);
      this.loading = false;
    }
  }
}
};
</script>

<style scoped>
/* Styl kontenera */
.container {
position: relative;
width: 99.6%;
max-width: 1920px;
height: 98vh;
background-color: rgba(0, 0, 0, 0.568);
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
backdrop-filter: blur(5px);
border: 1px solid rgba(161, 2, 175, 0.568);
}

/* Styl tekstu regulaminu */
.accept-rules-container {
text-align: center;
padding: 20px;
background: rgba(0, 0, 0, 0.7);
border-radius: 10px;
width: 95%;
height: 88vh;
color: #fff;
}

.accept-rules-container h1 {
margin-top: 1%;
background-color: #a100c9be;
padding: 10px;
border-radius: 20px;
box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
font-size: 24px;
font-weight: bold;
color: #ffffff;
text-align: center;
border: 1px solid #000000;
width: 98%;
}

.rules-text {
margin-top: 20px;
text-align: left;
}

.rules-text h3 {
margin-top: 1%;
background-color: #a100c9be;
padding: 10px;
border-radius: 20px;
box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
font-size: 24px;
font-weight: bold;
color: #ffffff;
text-align: center;
border: 1px solid #000000;
width: 98%;
}

.accept-rules-container p {
font-size: 20px;
}

.rules-text p {
margin: 10px 0;
}

.btn-accept {
margin-top: 20px;
background-color: #9d03cc;
color: #fff;
font-size: 16px;
padding: 10px 20px;
border: none;
border-radius: 5px;
cursor: pointer;
}

.btn-accept:hover {
background-color: #5903a0;
}

.btn-twitch {
display: inline-block;
background-color: #6441a5;
color: #fff;
font-size: 16px;
padding: 10px 20px;
margin-top: 10px;
text-align: center;
border-radius: 5px;
text-decoration: none;
}

.btn-twitch:hover {
background-color: #4b388f;
}
</style>