<template>
  <div class="user-list-container">
    <div class="text-userlist">Lista Użytkowników</div>

    <!-- Wyszukiwarka -->
    <input
      type="text"
      v-model="searchQuery"
      placeholder="Szukaj użytkownika..."
      @input="fetchUsers(1)"
      class="search-input"
    />

    <!-- Tabela użytkowników -->
    <table v-if="users.length > 0" class="user-table">
      <thead>
        <tr>
          <th>Avatar</th>
          <th>Discord ID</th>
          <th>Nazwa użytkownika</th>
          <th>Numer klienta</th>
          <th>Zaakceptowany Regulamin</th>
          <th>Data utworzenia</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id" @click="goToUserDetails(user.id)" class="clickable-row">
          <td><img :src="user.avatar" alt="Avatar" class="avatar"/></td>
          <td>{{ user.discordId }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.clientNumber }}</td>
          <td>{{ user.hasAcceptedRules ? 'Tak' : 'Nie' }}</td>
          <td>{{ formatDate(user.createdAt) }}</td>
        </tr>
      </tbody>
    </table>

    <p v-else>Brak użytkowników do wyświetlenia.</p>

    <!-- Paginacja -->
    <div class="pagination" v-if="totalPages > 1">
      <button :disabled="currentPage === 1" @click="fetchUsers(currentPage - 1)">Poprzednia</button>
      <span>Strona {{ currentPage }} z {{ totalPages }}</span>
      <button :disabled="currentPage === totalPages" @click="fetchUsers(currentPage + 1)">Następna</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserList',
  data() {
    return {
      users: [],
      currentPage: 1,
      totalPages: 1,
      searchQuery: ''
    };
  },
  methods: {
    async fetchUsers(page = 1) {
      const token = localStorage.getItem('token');
      if (!token) {
        this.$router.push('/login');
        return;
      }
      try {
        const response = await axios.get('https://api.incrp.pl/api/users', {
          params: {
            page,
            limit: 10, // Liczba użytkowników na stronie
            search: this.searchQuery
          },
          headers: { Authorization: `Bearer ${token}` }
        });

        this.users = response.data.users;
        this.currentPage = response.data.currentPage;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Błąd podczas pobierania użytkowników:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push('/login');
        }
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    goToUserDetails(userId) {
      this.$router.push({ name: 'user-details', params: { id: userId } });
    }
  },
  created() {
    this.fetchUsers(); // Pobierz użytkowników przy załadowaniu komponentu
  }
};
</script>

<style scoped>
.user-list-container {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  margin: 0 auto;
  backdrop-filter: blur(5px);
}

.text-userlist {
  margin-top: 1%;
  background-color: #a100c9be;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border: 1px solid #000000;
  width: 98%;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  max-height: 400px;
  overflow-y: scroll;
}

.user-table th, .user-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
  justify-content: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.clickable-row:hover {
  background-color: rgba(100, 100, 255, 0.1);
}

.search-input {
  margin-top: 0.5%;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #000000;
  background-color: #a100c9be;
  color: #ffffff;
  box-sizing: border-box;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  background-color: #6441a5;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>