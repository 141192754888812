<template>
  <div class="support-panel">
    <h3>Aktywne zgłoszenia</h3>
    <ul>
      <li v-for="(session, index) in supportSessions" :key="index">
        <span>Użytkownik: {{ session.userId }}</span>
        <button @click="acceptSupport(session.userId)">Przejmij czat</button>
      </li>
    </ul>

    <div v-if="currentChat" class="chat-window">
      <h3>Czat z użytkownikiem {{ currentChat.userId }}</h3>
      <div class="chat-messages">
        <div v-for="(message, index) in currentChat.messages" :key="index" class="message">
          <span>{{ message.sender }}: {{ message.message }}</span>
        </div>
      </div>

      <input v-model="supportMessage" placeholder="Napisz wiadomość..." />
      <button @click="sendSupportMessage">Wyślij</button>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client';

export default {
  data() {
    return {
      supportSessions: [],  // Lista aktywnych zgłoszeń
      currentChat: null,     // Obecny czat z użytkownikiem
      supportMessage: '',    // Wiadomość technika
      socket: null,          // Połączenie WebSocket
    };
  },
  created() {
    this.socket = io('https://api.incrp.pl/');

    // Odbieranie nowych zgłoszeń od użytkowników
    this.socket.on('newSupportRequest', (session) => {
      console.log("Otrzymano nowe zgłoszenie:", session);

      // Dodaj zgłoszenie do listy tylko, jeśli użytkownik nie istnieje w supportSessions
      const existingSession = this.supportSessions.find(s => s.userId === session.userId);
      if (!existingSession) {
        this.supportSessions.push({ userId: session.userId, messages: [] });
      }
    });

    // Odbieranie wiadomości od użytkownika
    this.socket.on('supportMessage', (message) => {
      console.log("Otrzymano wiadomość od użytkownika:", message);

      // Jeśli obecny czat jest aktywny, dodaj wiadomość użytkownika do wiadomości czatu
      if (this.currentChat && this.currentChat.userId === message.userId) {
        this.currentChat.messages.push({
          sender: 'user',
          message: message.message,
        });
      }
    });
  },
  methods: {
    acceptSupport(userId) {
      // Znajdź sesję użytkownika
      const session = this.supportSessions.find(s => s.userId === userId);
      if (session) {
        this.currentChat = session;  // Przejęcie czatu
        this.socket.emit('acceptSupport', userId);  // Powiadom serwer, że technik przejął czat
        console.log(`Przejęto czat użytkownika ${userId}`);
      } else {
        console.error(`Nie znaleziono sesji dla użytkownika ${userId}`);
      }
    },
    sendSupportMessage() {
      if (this.supportMessage.trim()) {
        // Dodaj wiadomość technika do bieżącej sesji czatu
        this.currentChat.messages.push({
          sender: 'support',
          message: this.supportMessage,
        });

        // Wyślij wiadomość do użytkownika przez WebSocket
        this.socket.emit('supportMessage', {
          userId: this.currentChat.userId,
          message: this.supportMessage,
        });

        this.supportMessage = '';  // Wyczyszczenie pola wiadomości
      }
    },
  },
};
</script>

<style scoped>
.tech-support-panel {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
}
.chat-messages {
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
}
.message {
  padding: 5px 0;
}
.chat-input {
  display: flex;
}
.chat-input input {
  flex: 1;
  padding: 5px;
}
.chat-input button {
  margin-left: 5px;
  padding: 5px 10px;
}
</style>