<template>
  <div id="map" ref="mapContainer" class="map-container"></div>
</template>

<script>
import L from 'leaflet';

export default {
  name: 'MapGta',
  data() {
    return {
      map: null,  // Obiekt mapy
      blips: [    // Lista blipów, które pojawią się na mapie
        {
          latlng: [500, 1010],  // Dostosuj współrzędne
          name: 'Policja',
          description: 'Komisariat policji w Los Santos.',
        },
        {
          latlng: [448, 900],
          name: 'Warsztat Benny\'s',
          description: 'Warsztat samochodowy Benny\'s do modyfikacji pojazdów.',
        },
        {
          latlng: [630, 852],
          name: 'Urząd Miasta',
          description: 'Potrzebujesz wyrobić nowe dokumenty to jest dobre miejsce.',
        }
      ]
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // Inicjalizacja mapy Leaflet
      const imageBounds = [[0, 0], [2048, 2048]];  // Dostosuj rozmiar mapy
      const mapOptions = {
        crs: L.CRS.Simple,
        minZoom: -1,
        maxZoom: 4,
        zoom: 0,
        center: [490, 490]  // Środek mapy
      };

      // Inicjalizowanie mapy i ładowanie obrazu mapy jako warstwy
      this.map = L.map(this.$refs.mapContainer, mapOptions);
      const imageUrl = '/mapa.png'; // Ścieżka do przesłanego obrazu mapy w folderze public
      L.imageOverlay(imageUrl, imageBounds).addTo(this.map);
      this.map.fitBounds(imageBounds);

      // Dodawanie blipów do mapy
      this.blips.forEach(blip => {
        const marker = L.marker(blip.latlng).addTo(this.map);
        marker.bindPopup(`<strong>${blip.name}</strong><br>${blip.description}`);
      });
    }
  }
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 98vh;
  position: relative;
}

.leaflet-container {
  background-color: transparent;
}
</style>