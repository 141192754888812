<template>
  <div class="MainApplications">
    <div class="text-applications">Podania</div>
    <div class="applications-container">
      <div class="application-card" v-for="(application, index) in applications" :key="index">
        <img :src="application.image" alt="Department Image" class="application-image" />
        <div class="application-info">
          <h3>{{ application.title }}</h3>
          <p>{{ application.description }}</p>
          <button v-if="application.isOpen" @click="openApplicationForm(application.type)" class="apply-button">
            Złóż podanie
          </button>
          <p v-else class="closed-message">Podania tymczasowo zamknięte</p>
        </div>
      </div>
    </div>

    <!-- Modal - Nakładka -->
    <div v-if="showForm" class="modal-overlay" @click.self="closeForm">
      <div class="modal-content">
        <h3>Złóż podanie do {{ currentApplicationType }}</h3>
        <form @submit.prevent="submitApplication">
          <!-- Formularz dla LSPD -->
          <div v-if="currentApplicationType === 'LSPD'">
            <label for="ic-name">Imię i Nazwisko [IC]:</label>
            <input v-model="formData.icName" type="text" id="ic-name" required />

            <label for="ooc-age">Wiek [OOC]:</label>
            <input v-model="formData.oocAge" type="number" id="ooc-age" required />

            <label for="experience">Opisz krótko doświadczenie w PD:</label>
            <textarea v-model="formData.experience" id="experience" required></textarea>

            <label for="why-join">Dlaczego chcesz do nas dołączyć?:</label>
            <textarea v-model="formData.whyJoin" id="why-join" required></textarea>

            <label for="career-plan">Jaki masz wstępny plan na swoją karierę w PD?:</label>
            <textarea v-model="formData.careerPlan" id="career-plan" required></textarea>
          </div>

          <!-- Inne formularze dla innych prac -->
          <div v-else-if="currentApplicationType === 'EMS'">
            <!-- Formularz EMS -->
            <label for="ems-reason">Dlaczego chcesz dołączyć do EMS?</label>
            <textarea v-model="applicationDescription" id="ems-reason" required></textarea>
          </div>

          <div v-else-if="currentApplicationType === 'BENNYS'">
            <!-- Formularz Benny's -->
            <label for="bennys-reason">Dlaczego chcesz dołączyć do Benny's?</label>
            <textarea v-model="applicationDescription" id="bennys-reason" required></textarea>
          </div>

          <button type="submit">Wyślij podanie</button>
          <button type="button" @click="closeForm">Anuluj</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import lspdImage from '../../assets/images/applications/IncRPlspd.png';
import emsImage from '../../assets/images/applications/incRPEMS.png';
import bennysImage from '../../assets/images/applications/incRPBennys.png';

export default {
  name: 'WebApplications',
  data() {
    return {
      applications: [
        {
          type: 'LSPD',
          title: 'Los Santos Police Department',
          description: 'Dołącz do sił policyjnych Los Santos.',
          image: lspdImage,
          isOpen: true, // Domyślnie otwarte
        },
        {
          type: 'EMS',
          title: 'Emergency Medical Services',
          description: 'Zostań częścią zespołu medycznego.',
          image: emsImage,
          isOpen: true, // Domyślnie otwarte
        },
        {
          type: 'BENNYS',
          title: 'Benny\'s Original Motor Works',
          description: 'Pracuj w warsztacie samochodowym.',
          image: bennysImage,
          isOpen: true, // Domyślnie otwarte
        },
      ],
      showForm: false,
      currentApplicationType: '',
      formData: {
        icName: '',
        oocAge: '',
        experience: '',
        whyJoin: '',
        careerPlan: '',
      },
      applicationDescription: '',
    };
  },
  methods: {
    // Pobieranie statusu podań z backendu
    mapStatus(status) {
      switch (status) {
        case 'pending':
          return 'Oczekuje na sprawdzenie';
        case 'accepted':
          return 'Zaakceptowane';
        case 'rejected':
          return 'Odrzucone';
        default:
          return 'Nieznany status';
      }
    },
    async fetchApplicationStatus() {
      try {
        const response = await axios.get('https://api.incrp.pl/api/applications-status');
        const statuses = response.data;
        this.applications.forEach((application) => {
          application.isOpen = statuses[application.type];
        });
      } catch (error) {
        console.error('Error fetching application status:', error);
      }
    },
    openApplicationForm(type) {
      this.currentApplicationType = type;
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
      this.formData = {
        icName: '',
        oocAge: '',
        experience: '',
        whyJoin: '',
        careerPlan: '',
      };
      this.applicationDescription = '';
    },
    async submitApplication() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('Musisz być zalogowany, aby złożyć podanie.');
          this.$router.push('/login');
          return;
        }

        // Pobierz identyfikator użytkownika z tokena
        const userIdResponse = await axios.get('https://api.incrp.pl/api/get-user', {
          headers: { Authorization: `Bearer ${token}` }
        });

        const userId = userIdResponse.data.id;

        let applicationData = {
          userId,
          type: this.currentApplicationType,
        };

        if (this.currentApplicationType === 'LSPD') {
          applicationData = {
            ...applicationData,
            description: `
              Imię i Nazwisko [IC]: ${this.formData.icName}\n
              Wiek [OOC]: ${this.formData.oocAge}\n
              Doświadczenie w PD: ${this.formData.experience}\n
              Dlaczego chcesz dołączyć: ${this.formData.whyJoin}\n
              Plan na karierę: ${this.formData.careerPlan}
            `,
          };
        } else {
          applicationData = {
            ...applicationData,
            description: this.applicationDescription,
          };
        }

        // Zapisz podanie do bazy danych
        await axios.post('https://api.incrp.pl/api/applications', applicationData, {
          headers: { Authorization: `Bearer ${token}` }
        });

        alert('Podanie zostało złożone!');
        this.closeForm();
      } catch (error) {
        console.error('Błąd podczas składania podania:', error);
        alert('Wystąpił błąd podczas składania podania.');
      }
    },
  },
  created() {
    this.fetchApplicationStatus(); // Pobieranie statusu podczas tworzenia komponentu
  },
};
</script>

<style scoped>
.MainApplications {
  width: 100%;
  height: 98vh;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.text-applications {
  margin-top: 1%;
  background-color: #a100c9be;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border: 1px solid #000000;
  width: 96%;
}

.applications-container {
  display: flex;
  justify-content: space-between;
  width: 96%;
  margin-top: 20px;
}

.application-card {
  background-color: rgba(36, 36, 36, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
  color: #ffffff;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.application-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 5px;
}

.application-info {
  text-align: center;
}

.apply-button {
  background-color: #a100c9be;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #8400a8;
}

.closed-message {
  background-color: #8400a8;
  color: red;
  font-weight: bold;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 83.5vw;
  height: 98vh;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  max-width: 100%;
  color: #ffffff;
}

.modal-content h3 {
  margin-bottom: 10px;
}

.modal-content label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.modal-content input,
.modal-content textarea {
  width: 96%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.modal-content button {
  padding: 10px 20px;
  background-color: #a100c9be;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.modal-content button:hover {
  background-color: #8400a8;
}
</style>