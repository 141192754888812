<template>
  <div class="user-details-container">
    <!-- Sprawdź, czy dane użytkownika zostały załadowane -->
    <div v-if="user">
      <div class="user-header">
        <img :src="user.avatar" alt="Avatar" class="avatar-large" />
        <h2>{{ user.username }}</h2>
      </div>

      <p><strong>Discord ID:</strong> {{ user.discordId }}</p>
      <p><strong>Nazwa użytkownika:</strong> {{ user.username }}</p>
      <p><strong>Numer klienta:</strong> {{ user.clientNumber }}</p>
      <p><strong>Zaakceptowany Regulamin:</strong> {{ user.hasAcceptedRules ? 'Tak' : 'Nie' }}</p>
      <p><strong>Data utworzenia:</strong> {{ formatDate(user.createdAt) }}</p>

      <!-- Sekcja wyświetlania ról użytkownika -->
      <div class="user-roles">
        <h4>Role:</h4>
        <ul v-if="user.SiteRoles && user.SiteRoles.length > 0">
          <li v-for="role in user.SiteRoles" :key="role.id">
            <!-- Wyświetlanie ikony, jeśli pole iconPath istnieje -->
            <img v-if="role.iconPath" :src="role.iconPath" alt="Role icon" class="role-icon" />
            {{ role.roleName || role.name || role.title }} <!-- Wyświetlanie nazwy roli -->
            <button @click="removeRole(role.id)">Usuń</button>
          </li>
        </ul>
        <p v-else>Brak ról dla użytkownika.</p>

        <!-- Dodawanie roli -->
        <div class="role-assignment">
          <h4>Dodaj rolę:</h4>
          <select v-model="selectedRoleId">
            <option v-for="role in availableRoles" :key="role.id" :value="role.id">
              {{ role.name || role.roleName || role.title }} <!-- Sprawdź różne pola -->
            </option>
          </select>
          <button @click="assignRole">Dodaj Rolę</button>
        </div>
      </div>
    </div>

    <!-- Komunikat, gdy dane użytkownika są jeszcze ładowane -->
    <p v-else>Ładowanie danych użytkownika...</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      user: null, // Przechowujemy dane użytkownika
      availableRoles: [], // Przechowujemy dostępne role
      selectedRoleId: null, // Przechowujemy wybraną rolę do przypisania
      isLoading: false, // Stan ładowania
    };
  },
  methods: {
    async fetchUser() {
      this.isLoading = true;
      const userId = this.$route.params.id; // Pobieramy ID użytkownika z parametrów trasy
      const token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/login");
        return;
      }

      try {
        // Pobieramy dane użytkownika
        const response = await axios.get(`https://api.incrp.pl/api/users/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log("Otrzymane dane użytkownika:", response.data);
        this.user = response.data;

        // Pobieramy dostępne role
        const rolesResponse = await axios.get("https://api.incrp.pl/api/roles", {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Logowanie odpowiedzi, aby sprawdzić strukturę danych ról
        console.log("Available roles data:", rolesResponse.data);

        this.availableRoles = rolesResponse.data;
      } catch (error) {
        console.error("Błąd podczas pobierania danych użytkownika:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      } finally {
        this.isLoading = false;
      }
    },
    async removeRole(roleId) {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        // Wyślij poprawnie skonfigurowane żądanie usunięcia roli
        await axios.post(`https://api.incrp.pl/api/remove-role-on-site`, {
          discordId: this.user.discordId, // Przekazujemy discordId użytkownika
          siteRoleId: roleId,             // Przekazujemy ID roli na stronie
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Odśwież dane użytkownika po usunięciu roli
        this.fetchUser();
      } catch (error) {
        console.error("Błąd podczas usuwania roli:", error);
      }
    },
    async assignRole() {
      const token = localStorage.getItem("token");
      if (!token || !this.selectedRoleId) return;

      try {
        // Wyślij żądanie przypisania roli
        await axios.post(`https://api.incrp.pl/api/assign-role-on-site`, {
          discordId: this.user.discordId,  // Przekazujemy discordId użytkownika
          siteRoleId: this.selectedRoleId, // Przekazujemy wybrane ID roli na stronie
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Odśwież dane użytkownika po przypisaniu roli
        this.fetchUser();
      } catch (error) {
        console.error("Błąd podczas przypisywania roli:", error);
      }
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
  created() {
    // Pobierz dane użytkownika przy załadowaniu komponentu
    this.fetchUser();
  },
};
</script>

<style scoped>
.user-details-container {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  margin: 0 auto;
  backdrop-filter: blur(5px);
}

.user-header {
  display: flex;
  align-items: center;
}

.avatar-large {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.user-roles {
  margin-top: 20px;
}

.user-roles h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.user-roles ul {
  list-style-type: none;
  padding: 0;
}

.user-roles li {
  font-size: 16px;
  margin-bottom: 5px;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 5px;
}

button {
  padding: 5px;
  margin-left: 10px;
  background-color: #f00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #c00;
}

.role-assignment {
  margin-top: 20px;
}

select {
  padding: 5px;
  margin-right: 10px;
}

.role-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  vertical-align: middle;  /* Ustawia ikonę w linii z tekstem */
  background: #ffffff;
  border-radius: 20%;
}
</style>