<template>
  <div class="profile-selection-container">
    <h1>Wybierz Profil</h1>
    <div class="profiles">
      <div
        v-for="profile in profiles"
        :key="profile.name"
        class="profile-card"
        :class="{ disabled: !profile.accessible }"
        @click="selectProfile(profile)"
      >
        <div class="profile-icon">
          <img :src="profile.icon" alt="Ikona profilu" />
        </div>
        <h2>{{ profile.name }}</h2>
      </div>
    </div>

    <div v-if="selectedProfile && !requiresPasswordChange" class="login-box">
      <h1>Zaloguj się jako {{ selectedProfile.name }}</h1>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="firstName">Imię:</label>
          <input
            type="text"
            id="firstName"
            v-model="firstName"
            required
            placeholder="Wprowadź imię"
          />
        </div>
        <div class="form-group">
          <label for="lastName">Nazwisko:</label>
          <input
            type="text"
            id="lastName"
            v-model="lastName"
            required
            placeholder="Wprowadź nazwisko"
          />
        </div>
        <div class="form-group">
          <label for="password">Hasło:</label>
          <input
            type="password"
            id="password"
            v-model="password"
            required
            placeholder="Wprowadź hasło"
          />
        </div>
        <button type="submit">Zaloguj się</button>
      </form>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>

    <div v-if="requiresPasswordChange" class="password-change-box">
        <h1>Ustaw nowe hasło</h1>
        <form @submit.prevent="changePassword">
            <div class="form-group">
            <label for="newPassword">Nowe hasło:</label>
            <input
                type="password"
                id="newPassword"
                v-model="newPassword"
                required
                placeholder="Wprowadź nowe hasło"
            />
            </div>
            <div class="form-group">
            <label for="confirmPassword">Potwierdź hasło:</label>
            <input
                type="password"
                id="confirmPassword"
                v-model="confirmPassword"
                required
                placeholder="Potwierdź nowe hasło"
            />
            </div>
            <button type="submit">Zmień hasło</button>
        </form>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      profiles: [
        { name: 'Administrator', icon: '/icons/admin.png', accessible: true },
        { name: 'LSPD', icon: '/icons/lspd.png', accessible: true },
        { name: 'EMS', icon: '/icons/ems.png', accessible: true },
        { name: 'DOJ', icon: '/icons/doj.png', accessible: false },
      ],
      selectedProfile: null,
      firstName: '',
      lastName: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: '',
      requiresPasswordChange: false, // Flaga informująca o konieczności zmiany hasła
    };
  },
  methods: {
    selectProfile(profile) {
      if (!profile.accessible) {
        this.errorMessage = 'Nie masz dostępu do tego profilu';
        return;
      }
      this.selectedProfile = profile;
      this.errorMessage = ''; // Reset błędu
    },
    async login() {
        try {
            const response = await axios.post('https://api.incrp.pl/api/tablet/loginTablet', {
                firstName: this.firstName,
                lastName: this.lastName,
                password: this.password,
            });

            console.log('Odpowiedź z serwera:', response);

            // Jeśli użytkownik musi zmienić hasło
            if (response.data.requiresPasswordChange) {
                this.requiresPasswordChange = true;
            } else if (response.data.token) {
                // Jeśli token jest zwrócony, użytkownik jest zalogowany
                localStorage.setItem('token', response.data.token);
                this.$router.push(`/desktop/${this.selectedProfile.name.toLowerCase()}`);
            } else {
                this.errorMessage = 'Błąd logowania: Brak tokenu w odpowiedzi.';
            }
        } catch (error) {
            console.error('Błąd przy logowaniu:', error);
            if (error.response) {
                this.errorMessage = error.response.data.message || 'Nie udało się zalogować';
            } else {
                this.errorMessage = 'Błąd sieciowy: Sprawdź połączenie z internetem.';
            }
        }
    },
    async changePassword() {
        if (this.newPassword !== this.confirmPassword) {
            this.errorMessage = 'Hasła nie pasują do siebie';
            return;
        }

        try {
            const response = await axios.post('https://api.incrp.pl/api/tablet/change-passwordTablet', {
                firstName: this.firstName,
                lastName: this.lastName,
                newPassword: this.newPassword,
            });

            // Zapisz token JWT w localStorage
            localStorage.setItem('token', response.data.token);

            // Przejdź na odpowiedni pulpit
            this.$router.push(`/desktop/${this.selectedProfile.name.toLowerCase()}`);
        } catch (error) {
            this.errorMessage =
                error.response?.data?.message || 'Nie udało się zmienić hasła';
        }
    },
  },
};
</script>

<style>
.profile-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #121212;
  color: white;
}
h1 {
  margin-bottom: 20px;
}
.profiles {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}
.profile-card {
  width: 150px;
  height: 200px;
  background-color: #1e1e2f;
  border: 2px solid transparent;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.profile-card:hover {
  border-image-source: linear-gradient(45deg, violet, blue);
  border-image-slice: 1;
}
.profile-card.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.profile-icon img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.login-box {
  background-color: #1e1e2f;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
}
.error-message {
  color: red;
  margin-top: 10px;
}
</style>