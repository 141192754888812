<template>
  <div class="MainCharakterList">
    <div class="text-charakterlist">{{ $t('charakter-list.title') }}</div>
    <div v-if="characters.length > 0" class="characters-container">
      <div 
        class="character-card clickable-card" 
        v-for="character in characters" 
        :key="character.citizenid" 
        @click="goToCharacterDetails(character.citizenid)"
      >
        <div class="character-info">
          <h3>{{ $t('charakter-list.info.steam') }}: {{ character.name }}</h3>
          <p><strong>{{ $t('charakter-list.info.citizenID') }}:</strong> {{ character.citizenid }}</p>
          <p><strong>{{ $t('charakter-list.info.firstname-lastname') }}:</strong> {{ character.charinfo.firstname }} {{ character.charinfo.lastname }}</p>
          <p><strong>{{ $t('charakter-list.info.money-bank') }}:</strong> ${{ character.money.bank }}</p>
          <p><strong>{{ $t('charakter-list.info.job-label') }}:</strong> {{ character.job.label }}</p>
          <p><strong>{{ $t('charakter-list.info.gang-label') }}:</strong> {{ character.gang.label }}</p>
        </div>
        <div class="character-avatar">
          <img :src="profileImage(character)" alt="Avatar" class="avatar"/>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="not-charakter">
        <p>{{ $t('charakter-list.error') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import defaultAvatarMale from '@/assets/images/ped/profile-male.jpg'; // Zdjęcie dla mężczyzn
import defaultAvatarFemale from '@/assets/images/ped/profile-female.jpg'; // Zdjęcie dla kobiet

export default {
  name: 'WebCharakterList',
  data() {
    return {
      characters: []  // Lista postaci użytkownika
    };
  },
  methods: {
    async fetchCharacters() {
      const token = localStorage.getItem('token');
      
      console.log("Token przed wysłaniem zapytania:", token);

      if (token) {
        try {
          const response = await axios.get('https://api.incrp.pl/api/characters', {
            headers: { Authorization: `Bearer ${token}` }
          });

          // Parsowanie odpowiednich pól JSON
          this.characters = response.data.map(character => {
            return {
              ...character,
              charinfo: JSON.parse(character.charinfo),
              money: JSON.parse(character.money),
              job: JSON.parse(character.job),
              gang: JSON.parse(character.gang)
            };
          });
          
          console.log('Postacie:', this.characters);
        } catch (error) {
          console.error('Błąd podczas pobierania postaci:', error.response);
        }
      }
    },
    profileImage(character) {
      if (character.charinfo.gender === 'male') {
        return defaultAvatarMale;
      } else if (character.charinfo.gender === 'female') {
        return defaultAvatarFemale;
      } else {
        return defaultAvatarMale;
      }
    },
    goToCharacterDetails(citizenid) {
      this.$router.push({ name: 'CharacterDetails', params: { citizenid } });
    }
  },
  created() {
    this.fetchCharacters();
  }
};
</script>

<style scoped>
.MainCharakterList {
  width: 100%;
  height: 98vh;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  padding: 20px;
}

.text-charakterlist {
  margin-top: 1%;
  background-color: #a100c9be;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border: 1px solid #000000;
  width: 96%;
}

.not-charakter {
  margin-top: 30%;
  background-color: #a100c9be;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #000000;
  text-align: center;
}

.characters-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  width: 96%;
}

.character-card {
  background-color: rgba(36, 36, 36, 0.8);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
  color: #ffffff;
  padding: 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.character-card:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.character-info {
  width: 70%;
}

.character-avatar {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ac00e0;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
}
</style>