<template>
  <div id="app">
    <aside :class="{ close: isClosed }">
      <div class="head">
        <div class="logo">
          <img src="@/assets/logo.png" alt="logo">
          <h2 class="logo-title"><span>{{ $t('sidebar.title') }}</span></h2>
        </div>
        <i class='bx bx-menu hamburger-menu' @click="toggleSidebar"></i>
      </div>

      <div class="nav">
        <!-- Elementy menu -->
        <div class="menu" :class="{ active: activeMenu === 'dashboard' }" @click="setActiveMenu('dashboard')">
          <i class='bx bx-home'></i>
          <span>{{ $t('sidebar.home') }}</span>
        </div>

        <!-- Dodaj swoje inne elementy menu tutaj -->

        <div v-if="isLoggedIn && hasFivemRank" class="menu" :class="{ active: activeMenu === 'fivem' }" @click="toggleDropdown('fivem')">
          <FivemIcon />
          <span>{{ $t('sidebar.fivem') }}</span>
          <i class="bx bx-chevron-down" v-if="activeMenu === 'fivem'"></i>
        </div>
        <div v-if="hasFivemRank" class="menu-dropdown" :class="{ show: activeMenu === 'fivem' }">
          <div class="sub-menu">
            <router-link v-if="isLoggedIn && hasFivemRank" to="/applications" class="menu">{{ $t('sidebar.fivem-menu.applications') }}</router-link>
            <router-link v-if="isLoggedIn && hasFrakcjeRank" to="/tablet" class="menu">{{ $t('sidebar.fivem-menu.komputer') }}</router-link>
            <router-link v-if="isLoggedIn && hasAplicationRank" to="/podaniasetting" class="menu">Ustawienia Podań</router-link>
          </div>
        </div>

        <div v-if="isLoggedIn" class="menu" :class="{ active: activeMenu === 'settings' }" @click="toggleDropdown('settings')">
          <i class='bx bx-cog'></i>
          <span>{{ $t('sidebar.setting') }}</span>
          <i class="bx bx-chevron-down" v-if="activeMenu === 'settings'"></i>
        </div>
        <div class="menu-dropdown" :class="{ show: activeMenu === 'settings' }">
          <div class="sub-menu">
            <router-link v-if="isLoggedIn" to="/profile" class="menu">{{ $t('sidebar.profile-menu') }}</router-link>
            <router-link v-if="isLoggedIn" to="/lista-postaci" class="menu">{{ $t('sidebar.character-list') }}</router-link>
          </div>
        </div>

        <div class="menu" :class="{ active: activeMenu === 'discord' }" @click="setActiveMenu('discord')">
          <i class='bx bxl-discord-alt'></i>
          <span>{{ $t('sidebar.discord') }}</span>
        </div>

        <div v-if="isLoggedIn && hasAdminRank" class="menu" :class="{ active: activeMenu === 'admin-web' }" @click="toggleDropdown('admin-web')">
          <i class='bx bx-code-alt'></i>
          <span>{{ $t('sidebar.admin-web') }}</span>
          <i class="bx bx-chevron-down" v-if="activeMenu === 'admin-web'"></i>
        </div>
        <div v-if="hasAdminRank" class="menu-dropdown" :class="{ show: activeMenu === 'admin-web' }">
          <div class="sub-menu">
            <router-link v-if="isLoggedIn && hasAdminRank" to="/settingsadmin" class="menu">{{ $t('sidebar.admin-menu-web.setting-web') }}</router-link>
            <router-link v-if="isLoggedIn && hasAdminRank" to="/userslist" class="menu">{{ $t('sidebar.admin-menu-web.users-lists') }}</router-link>
          </div>
        </div>

        <div v-if="isLoggedIn && hasModeratorRank" class="menu" :class="{ active: activeMenu === 'moderator' }" @click="toggleDropdown('moderator')">
          <i class='bx bx-shield'></i>
          <span>{{ $t('sidebar.moderator-panel') }}</span>
          <i class="bx bx-chevron-down" v-if="activeMenu === 'moderator'"></i>
        </div>
        <div v-if="hasModeratorRank" class="menu-dropdown" :class="{ show: activeMenu === 'moderator' }">
          <div class="sub-menu">
            <!-- Możliwe linki dla moderatora -->
          </div>
        </div>
      </div>

      <div class="foot">
        <div v-if="isLoggedIn" class="profile" :class="{ hidden: !showProfile }">
          <img :src="userAvatar" alt="Avatar" class="avatar"/>
          <div class="info">
            <span class="name">{{ user.username }}</span>
            <span class="client-number">{{ user.clientNumber }}</span>
          </div>
        </div>

        <!-- <div v-else class="menu menu-login" @click="login">
          <i class="bx bx-log-in"></i>
          <span>{{ $t('sidebar.login-btn') }}</span>
        </div>

        <div v-if="isLoggedIn" class="menu menu-logout" @click="logout">
          <i class="bx bx-log-out"></i>
          <span>{{ $t('sidebar.logout-btn') }}</span>
        </div> -->
      </div>
    </aside>
    
    <div class="main-content">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus';
import FivemIcon from '@/components/icons/FivemIcon.vue';
import axios from 'axios';

export default {
  name: 'WebSidebar',
  components: {
    FivemIcon,
  },
  data() {
    return {
      user: null,
      isLoggedIn: false,
      isClosed: false,
      activeMenu: '',
      showProfile: true,
      adminRankId: 3,
      moderatorRankId: 2,
      fivemRankId: 1,
      applicationRankIds: [3, 4, 5, 6],
      frkacjeRankIds: [3],
      isChatOpen: false,
    };
  },
  computed: {
    userAvatar() {
      return this.user?.avatar || require('@/assets/images/profile.jpg');
    },
    hasFivemRank() {
      return this.user?.SiteRoles?.some(role => role.id === this.fivemRankId);
    },
    hasModeratorRank() {
      return this.user?.SiteRoles?.some(role => role.id === this.moderatorRankId);
    },
    hasAdminRank() {
      return this.user?.SiteRoles?.some(role => role.id === this.adminRankId);
    },
    hasAplicationRank() {
      return this.user?.SiteRoles?.some(role => this.applicationRankIds.includes(role.id));
    },
    hasFrakcjeRank() {
      return this.user?.SiteRoles?.some(role => this.frkacjeRankIds.includes(role.id));
    },
  },
  methods: {
    toggleChatWindow() {
      this.isChatOpen = !this.isChatOpen;
    },
    async checkLoginStatus() {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('https://api.incrp.pl/api/profile', {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.user = response.data;
          this.isLoggedIn = true;
          localStorage.setItem('user', JSON.stringify(this.user));
          this.isChatOpen = true;
        } catch (error) {
          console.error('Error fetching user data:', error);
          this.logout();
        }
      } else {
        this.user = null;
        this.isLoggedIn = false;
      }
    },
    toggleSidebar() {
      this.isClosed = !this.isClosed;
    },
    setActiveMenu(menu) {
      this.activeMenu = this.activeMenu === menu ? '' : menu;
      if (menu === 'dashboard') {
        this.$router.push('/');
      } else if (menu === 'discord') {
        window.open('https://discord.incrp.pl/', '_blank');
      }
    },
    toggleDropdown(menu) {
      this.activeMenu = this.activeMenu === menu ? '' : menu;
    },
    login() {
      window.location.href = '/login';
    },
    async logout() {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.isLoggedIn = false;
      this.user = null;
      this.$router.push('/');
      eventBus.emit('loginStatusChanged');
    },
    updateUser(user) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    toggleFooterSection(section) {
      if (section === 'profile') {
        this.showProfile = !this.showProfile;
      }
    }
  },
  created() {
    this.checkLoginStatus();
    eventBus.on('loginStatusChanged', this.checkLoginStatus);
    eventBus.on('userUpdated', this.updateUser);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #9d03cc, #760194);
  display: flex;
}

#app {
  display: flex;
  height: 98vh;
}

aside {
  background: rgba(3, 3, 3, 0.568);
  width: 250px;
  height: 98vh;
  box-shadow: 0 4px 30px rgba(12, 12, 12, 0.568);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s; /* Smooth transition for sidebar width */
  position: fixed;
  border-radius: 20px;
  backdrop-filter: blur(5px);
}

aside.close {
  width: 100px;
}

.main-content {
  flex: 1;
  margin-left: 260px; /* Default margin for expanded sidebar */
  transition: margin-left 0.3s; /* Smooth transition for content margin */
  height: 98vh; /* Ensure the content takes full viewport height */
}

aside.close + .main-content {
  margin-left: 110px; /* Adjust margin for collapsed sidebar */
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head .logo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.head .logo img {
  width: 50px;
}

.head .logo .logo-title span {
  color: #a603c7;
}

.head .bx-menu {
  font-size: 45px;
  cursor: pointer;
  color: #ffffff;
}

.nav {
  border-top: solid #ffffff;
  margin-top: 15px;
  padding-top: 10px;
  flex: 1;
}

aside:not(.close) .nav {
  overflow-y: auto;
}

.nav::-webkit-scrollbar {
  width: 0;
}

.menu {
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  gap: 10px;
  align-items: center;
  transition: 0.3s;
  justify-content: space-between;
  text-decoration: none;
}

.menu:hover {
  background: #690299;
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.menu i {
  font-size: 25px;
}

.menu span {
  font-weight: 600;
  font-size: 17px;
  flex: 1;
}

.foot {
  padding-top: 20px;
  border-top: solid #F2F2F2;
  color: #ffffff;
}

.profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.profile img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.profile .info {
  display: flex;
  flex-direction: column;
}

.profile .info .name {
  font-weight: 600;
  white-space: nowrap;
}

.profile .info .client-number {
  font-size: 13px;
  color: #fff5f5;
  font-weight: bold;
}

.foot .menu-logout {
  color: red;
  background: #f2f2f2bb;
  margin-top: 15px;
}

.foot .menu-logout:hover {
  background: #ff010175;
  color: red;
}

.menu-login {
  color: green;
  background: #f2f2f2bb;
  margin-top: 15px;
}

.menu-login:hover {
  background: rgba(0, 128, 0, 0.5);
  color: green;
}

aside.close .logo {
  display: none;
}

aside.close .bx-menu {
  width: 100%;
  text-align: center;
}

aside.close .menu span {
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  color: rgb(160, 4, 180);
  padding: 10px;
  border-radius: 8px;
  margin-left: 70px;
  font-size: 16px;
  opacity: 0;
  transition: 0.3s;
  pointer-events: none;
  width: 200%;
}

aside.close .menu i:nth-child(1) {
  width: 100%;
  text-align: center;
}

aside.close .menu i:nth-child(3) {
  display: none;
}

aside.close .menu:hover span {
  margin-left: 60px;
  opacity: 1;
}

.nav .menu.active {
  background: linear-gradient(90deg, #9d03cc, #760194);
  color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.menu i:nth-child(3) {
  transition: 0.3s;
}

.menu.active i:nth-child(3) {
  transform: rotate(180deg);
}

.menu-dropdown {
  display: none;
  padding-left: 45px;
}

.menu-dropdown .sub-menu {
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.sub-menu .menu {
  font-size: 17px;
  margin-top: 5px;
}

.menu-dropdown.show {
  display: block;
}

aside.close .profile {
  display: flex;
  justify-content: center;
}

aside.close .profile .info {
  display: none;
}
</style>