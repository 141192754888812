<template>
  <div class="MainSettingAdmin">
    <div class="text-adminsetting">{{ $t('admin-menu.title') }}</div>
    <div class="content">
      <div class="settings-container">
        <div v-if="filteredSettings.length">
          <div v-for="setting in filteredSettings" :key="setting.id" class="setting-item">
            <span>{{ getFriendlyName(setting.setting_key) }}</span>
            <input
              type="checkbox"
              v-model="setting.value"
              @change="updateSetting(setting)"
            />
          </div>
        </div>
        <div v-else>
          <p>Brak dostępnych ustawień.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WebSettingsApplications',
  data() {
    return {
      settings: [],
      userRoles: [],
      friendlyNames: {
        applicationsLSPD: 'Możliwość pisania podania do LSPD',
        applicationsEMS: 'Możliwość pisania podania do EMS',
        applicationsBENNYS: 'Możliwość pisania podania do Benny'
      },
      settingsVisibility: {
        applicationsLSPD: [4],
        applicationsEMS: [5],
        applicationsBENNYS: [6]
      }
    };
  },
  computed: {
    filteredSettings() {
      return this.settings.filter(setting => {
        const visibleForRoles = this.settingsVisibility[setting.setting_key] || [];
        return visibleForRoles.some(roleId => this.userRoles.includes(roleId));
      });
    }
  },
  methods: {
    async fetchSettings() {
      try {
        const response = await axios.get('https://api.incrp.pl/admin/settings');
        this.settings = response.data.map(setting => ({
          ...setting,
          value: setting.value === 'true' || setting.value === 'online'
        }));

        const user = JSON.parse(localStorage.getItem('user'));
        this.userRoles = user?.SiteRoles.map(role => role.id) || [];
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    },
    async updateSetting(setting) {
      try {
        const updatedSetting = {
          ...setting,
          value: setting.value ? 'true' : 'false'
        };

        if (setting.setting_key === 'siteStatus') {
          updatedSetting.value = setting.value ? 'online' : 'maintenance';
        }

        await axios.post('https://api.incrp.pl/admin/settings', updatedSetting);
        alert('Setting updated successfully!');
      } catch (error) {
        console.error('Error updating setting:', error);
        alert('There was an error updating the setting.');
      }
    },
    getFriendlyName(key) {
      return this.friendlyNames[key] || key;
    }
  },
  created() {
    this.fetchSettings();
  }
};
</script>

<style scoped>
.MainSettingAdmin {
  width: 100%;
  height: 98vh;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.text-adminsetting {
  margin-top: 1%;
  background-color: #a100c9be;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border: 1px solid #000000;
  width: 98%;
}

.settings-container {
  margin-top: 2%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.setting-item:last-child {
  border-bottom: none;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
}

input[type="checkbox"] {
  transform: scale(1.5);
}
</style>