<template>
  <div class="MainInfoFivem">
    <div class="frame">
      <img src="@/assets/logo.png" alt="Logo" class="server-logo" />
      <div class="text-infofivem">Informacje o serwerze</div>
      <div class="server-status-container">
        <div v-if="serverStatus === 'online'" class="server-status online">
          Serwer jest włączony
        </div>
        <div v-else class="server-status offline">
          Serwer jest wyłączony
        </div>
        <div class="server-info" v-if="serverStatus === 'online'">
          <p>Nazwa serwera: {{ serverName }}</p>
          <p>Liczba graczy: {{ playerCount }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WebInfoFivem',
  data() {
    return {
      serverStatus: 'offline',
      playerCount: 0,
      serverName: '',
      serverId: 'y59l4m',
    };
  },
  methods: {
    async fetchServerStatus() {
      try {
        const response = await axios.get(`https://servers-frontend.fivem.net/api/servers/single/${this.serverId}`);
        const serverData = response.data.Data;

        if (serverData) {
          this.serverStatus = 'online';
          this.playerCount = serverData.players.length;
          this.serverName = serverData.hostname;
        } else {
          this.serverStatus = 'offline';
          this.playerCount = 0;
          this.serverName = '';
        }
      } catch (error) {
        console.error('Błąd podczas pobierania stanu serwera:', error);
        this.serverStatus = 'offline';
      }
    }
  },
  mounted() {
    this.fetchServerStatus();

    setInterval(this.fetchServerStatus, 60000);
  }
};
</script>

<style scoped>
.MainInfoFivem {
  width: 100%;
  height: 98vh;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.frame {
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
  background-color: rgba(36, 36, 36, 0.8);
  width: 80%;
  max-width: 800px;
  text-align: center;
}

.server-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.text-infofivem {
  margin-top: 1%;
  background-color: #a100c9be;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 8px 6px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border: 1px solid #000000;
  width: 96%;
}

.server-status-container {
  margin-top: 20px;
}

.server-status {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffffa9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.server-status.online {
  color: green;
  border: 2px solid green;
}

.server-status.offline {
  color: red;
  border: 2px solid red;
}

.server-info {
  font-size: 18px;
  padding: 10px;
  margin-top: 10px;
  background-color: #ffffffa9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>