<template>
  <div class="auth-container">
    <div class="container">
      <div class="item">
        <div class="logo">
          <img src="@/assets/logo.png" alt="Logo" class="logo-image" />
        </div>
        <div class="text-item">
          <h2>{{ $t('login.text-welcome') }} <span>{{ $t('login.text-welcome2') }}</span></h2>
          <p>{{ $t('login.text-welcome3') }}</p>
          <div class="social-icon">
            <a href="https://discord.incrp.pl"><i class='bx bxl-discord'></i></a>
          </div>
        </div>
      </div>
      <div class="login-section">
        <div class="form-box">
          <button class="btn discord-btn" @click="redirectToDiscord">{{ $t('login.discord-login') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'WebLogin',
  methods: {
    redirectToDiscord() {
      const discordAuthUrl = `https://discord.com/oauth2/authorize?client_id=1272571294730424340&response_type=code&redirect_uri=https%3A%2F%2Fapi.incrp.pl%2Fdiscord%2Fcallback&scope=identify+guilds+email+guilds.join`;
      window.location.href = discordAuthUrl;
    },
    async handleDiscordCallback(code) {
      try {
        const response = await axios.post('https://api.incrp.pl/discord/callback', { code });
        const { token, user } = response.data;

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);

        const isAdmin = user?.SiteRoles?.some(role => role.id === 3);

        // Jeśli użytkownik nie jest adminem, a tryb konserwacji jest włączony, przekieruj na stronę konserwacji
        const maintenanceMode = await axios.get('https://api.incrp.pl/admin/settings');
        const maintenanceEnabled = maintenanceMode.data.find(setting => setting.setting_key === 'maintenance_mode').value === 'true';

        if (maintenanceEnabled && !isAdmin) {
          this.$router.push('/maintenance');
        } else if (!user.hasAcceptedRules) {
          this.$router.push('/accept-rules');
        } else {
          this.$router.push('/profile');
        }
      } catch (error) {
        console.error('Error during Discord login:', error.response ? error.response.data : error);
      }
    }
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      this.handleDiscordCallback(code);
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;1,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  height: 100vh;
  width: 100%;
  background: #000;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98vh;
  width: 100%;
}

.container {
  position: relative;
  width: 75%;
  max-width: 1200px;
  height: 550px;
  background-color: rgba(0, 0, 0, 0.568);
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 30px rgba(161, 2, 175, 0.568);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(161, 2, 175, 0.568);
}

.item {
  flex: 1;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
}

.logo {
  text-align: center;
}

.logo-image {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.text-item h2 {
  font-size: 40px;
  line-height: 1;
}

.text-item span {
  color: #9d03cc;
}

.text-item p {
  font-size: 16px;
  margin: 20px 0;
}

.social-icon a i {
  color: #fff;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.5s ease;
}

.social-icon a:hover i {
  transform: scale(1.2);
}

.login-section {
  display: flex;
  justify-content: center; /* Wyśrodkowanie przycisku */
  align-items: center; /* Wyśrodkowanie w pionie */
  height: 100%; /* Aby przycisk był wyśrodkowany w całej wysokości sekcji */
  padding: 0 20px; /* Dostosowanie odstępów od krawędzi */
}

.form-box {
  text-align: center;
  margin-top: 40px; /* Odstęp od górnej części kontenera */
}

.btn.discord-btn {
  background-color: #9d03cc;
  color: #fff;
  font-size: 16px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease;
  text-align: center; /* Wyśrodkowanie tekstu */
}

.btn.discord-btn:hover {
  background-color: #5903a0;
}
</style>