<template>
  <div class="maintenance-page">
    <h1>Prace Techniczne</h1>
    <p>Strona została tymczasowo wyłączona. Przeprowadzamy prace techniczne. Prosimy o cierpliwość.</p>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage',
};
</script>

<style scoped>
.maintenance-page {
  text-align: center;
  padding: 50px;
  background-color: #333;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
