import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';

import en from './locales/en.json';
import pl from './locales/pl.json';

const i18n = createI18n({
  locale: 'pl', // ustawienia domyślnego języka
  fallbackLocale: 'en', // język, do którego ma wrócić, jeśli nie znajdzie tłumaczenia
  messages: {
    en,
    pl
  }
});

createApp(App)
  .use(router)
  .use(i18n)
  .mount('#inc-web');